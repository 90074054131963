import get from 'lodash/get'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { connect } from 'react-redux'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import NotificationsService from 'src/api/Notifications'
import { checkTokenExpiration } from 'src/api/request'
import UsersService from 'src/api/Users'
import Icon from 'src/Components/Icons'
import { useAuthentication } from 'src/hooks/useAuthentication'
import useCurrentWidth from 'src/hooks/useCurrentWidth'
import useNotifications from 'src/hooks/useNotifications'
import useOutsideClick from 'src/hooks/useOutsideClick'
import { resetRegistratioInfoState } from 'src/store/reducers/registrationInfo'
import { SignOut, UpdateProfile } from 'src/store/reducers/user'
import { User } from 'src/Types/User'
import Cookies from 'universal-cookie'

import Button from '../Buttons'
import NotificationDropdown from '../Dropdowns/NotificationDropdown'
import ProfileDropdown from '../Dropdowns/ProfileDropdown'
import NotificationModal from '../Modals/NotificationModal'

interface NavigationProps {
  isTransparent?: boolean
  user: User
  signOut: () => void
  updateProfile: (user: User) => void
  hasCtaGG?: boolean
  stayTransparentSticky?: boolean
  goBackToRoute?: string
}

const defaultLoggedIn = require('../../assets/images/icons/user-placeholder-blue-600.svg').default
const defaultLoggedOut = require('../../assets/images/icons/user-placeholder-light-grey.svg').default

function Navigation(props: NavigationProps) {
  const history = useHistory()
  const location = useLocation()

  const { handleOauthSignIn } = useAuthentication()
  const [cookies] = useState(new Cookies())
  const [navMenuIsOpen, setNavMenuIsOpen] = useState(false)
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false)
  const [notificationDropdownIsOpen, setNotificationDropdownIsOpen] = useState(false)
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false)

  const signOut = get(props, 'signOut', null)
  const userId = get(props, 'user.id', null)
  const userRole = get(props, 'user.role', null)
  const userLoggedIn = userId != null

  const userPlaceholderBgImg = useMemo(() => {
    return props?.user?.profile?.logo?.length > 0
      ? props?.user?.profile?.logo
      : userLoggedIn
      ? defaultLoggedIn
      : defaultLoggedOut
  }, [props?.user?.profile?.logo, userLoggedIn])

  const { notificationsCount, refetch } = useNotifications({
    enabled: userId != null && !location.pathname.includes('/preview'),
    staleTime: 1000 * 5,
    cacheTime: 1000 * 5,
    retry: 0,
  })

  const [notificationsAllRead, setNotificationsAllRead] = useState(notificationsCount === 0)

  const handleMarkAllAsRead = () => {
    if (notificationsCount > 0) {
      setNotificationsAllRead(true)
      NotificationsService.markAllAsRead().then(() => {
        refetch()
      })
    }
  }

  const notificationWrapper = useRef()
  useOutsideClick(notificationWrapper, () => {
    if (notificationDropdownIsOpen) {
      setNotificationDropdownIsOpen(false)
      handleMarkAllAsRead()
    }
  })

  const toggleProfileMenu = (ev) => {
    if (window.innerWidth >= 1280) ev.preventDefault()
    setProfileMenuIsOpen((profileMenuIsOpen) => !profileMenuIsOpen)
  }

  const handleSignOut = (noRedirect?: boolean) => {
    props.signOut()
    cookies.remove('token')
    if (!noRedirect) history.push('/')
  }

  const handleCloseDropdown = () => {
    setNotificationDropdownIsOpen(false)
    handleMarkAllAsRead()
  }

  const handleToggleDropdown = () => {
    setNotificationDropdownIsOpen(!notificationDropdownIsOpen)
    if (notificationDropdownIsOpen) handleMarkAllAsRead()
  }

  const logOutUser = useCallback(() => {
    signOut()
    cookies.remove('token')
    toast.error('Your session has expired. Please sign in again.')
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }, [cookies, signOut])

  useEffect(() => {
    document.body.style.overflow = showNotificationModal || navMenuIsOpen ? 'hidden' : 'unset'
  }, [showNotificationModal, navMenuIsOpen])

  useEffect(() => {
    const cookies = new Cookies()
    if (userLoggedIn && checkTokenExpiration(cookies.get('token'))) {
      logOutUser()
    } else if (userLoggedIn) {
      const id = setInterval(() => {
        if (userLoggedIn && checkTokenExpiration(cookies.get('token'))) {
          logOutUser()
        }
      }, 1000)

      return () => clearInterval(id)
    }
  }, [logOutUser, userLoggedIn])

  const [stickyHeader, setStickyHeader] = useState<boolean>(false)
  const [stickyGoBackToRoute, setStickyGoBackToRoute] = useState<boolean>(false)

  useEffect(() => {
    const scrollCallBack = window.addEventListener('scroll', () => {
      setStickyGoBackToRoute(window.pageYOffset > (window.innerWidth >= 1024 ? 320 : 250))
      setStickyHeader(window.pageYOffset > 80)
    })
    return () => {
      window.removeEventListener('scroll', () => scrollCallBack)
    }
  }, [])

  let width = useCurrentWidth()

  const isIframe = window ? 'parentIFrame' in window : false

  return (
    <nav
      className={`golf-header__navigation ${props.isTransparent && !stickyHeader && 'is-transparent'} ${
        stickyHeader ? 'is-sticky' : 'is-not-sticky'
      }`}
      style={{ display: isIframe ? 'none' : 'block' }}>
      <div
        className={`is-1-of-1 golf-header__navigation--sticky-wrapper ${stickyHeader ? 'is-sticky' : ''} ${
          props.stayTransparentSticky ? 'stay-transparent-on-sticky' : ''
        }`}>
        <div className={'golf-container py-14 sm_tablet:py-18 sm_desktop:py-0 relative d-flex items-center'}>
          <NavLink
            to={userLoggedIn && userRole === 'admin' ? '/homepage' : userLoggedIn ? '/profile/events' : '/'}
            className={`golf-header__logo ${
              ((props.isTransparent && !stickyHeader) || props.stayTransparentSticky) && 'is-white'
            } ${
              props.goBackToRoute && stickyHeader && stickyGoBackToRoute ? 'd-none sm_tablet:d-block' : ''
            }`}></NavLink>
          {props.goBackToRoute && stickyHeader && stickyGoBackToRoute && (
            <Link className={'golf__link d-inline-flex sm_tablet:ml-32 -ml-8'} to={props.goBackToRoute}>
              <Icon name="arrow" className="golf-icon left orange-icon mr-4" />
              Back to club page
            </Link>
          )}
          {userRole === 'admin' && (
            <NavLink
              exact
              to={'/admin/events/list'}
              className={'golf-header__link is-admin-events-link is-on-nav-mobile ml-auto'}
              activeClassName="is-active">
              <span>Events</span>
            </NavLink>
          )}
          {userLoggedIn && (
            <button
              className={`${
                userRole === 'admin' ? '' : 'ml-auto'
              } mr-16 reset-btn sm_desktop:d-none golf-header__notification-btn`}
              onClick={() => {
                setShowNotificationModal(!showNotificationModal)
              }}>
              <Icon
                name="bell"
                className={`golf-icon mr-7 ultra_dark_grey-icon bell ${notificationDropdownIsOpen ? 'active' : ''}`}
              />
              {!notificationsAllRead && (
                <span className="golf-icon is-xsmall is-number">
                  {notificationsCount < 100 ? notificationsCount : '99+'}
                </span>
              )}
            </button>
          )}

          {!props.hasCtaGG && (
            <>
              {userLoggedIn && (
                <button
                  onClick={() => {
                    setNavMenuIsOpen(!navMenuIsOpen)
                  }}
                  className={`golf-header__nav-menu-btn ${userLoggedIn ? '' : 'ml-auto'} ${
                    navMenuIsOpen ? 'is-active' : ''
                  }`}
                  style={{ backgroundImage: `url('${userPlaceholderBgImg}')` }}>
                  <span></span>
                </button>
              )}
              <ul
                className={`golf-header__links-wrapper ${userRole === 'admin' ? '' : 'sm_desktop:ml-auto'} ${
                  navMenuIsOpen ? 'is-active' : ''
                }`}>
                {userLoggedIn && (
                  <>
                    <li ref={notificationWrapper} className={'golf-header__list-item--notification'}>
                      <button
                        className="reset-btn golf-header__notification-btn"
                        onClick={() => {
                          if (window.innerWidth < 1024) {
                            setShowNotificationModal(!showNotificationModal)
                          } else {
                            handleToggleDropdown()
                          }
                        }}>
                        <Icon
                          name="bell"
                          color="ultra_dark_grey"
                          className={`golf-icon ml-2 mr-7 bell ${notificationDropdownIsOpen ? 'active' : ''}`}
                        />
                        {!notificationsAllRead && (
                          <span className="golf-icon is-xsmall is-number">
                            {notificationsCount < 100 ? notificationsCount : '99+'}
                          </span>
                        )}
                      </button>
                      {notificationDropdownIsOpen && (
                        <NotificationDropdown
                          notificationsAllRead={notificationsAllRead}
                          closeDropdown={handleCloseDropdown}
                        />
                      )}
                    </li>
                    {!navMenuIsOpen && width >= 1280 && (
                      <li className={'golf-header__list-item--user'}>
                        <NavLink
                          to={'/profile'}
                          className={'golf-header__link is-user'}
                          onClick={(ev) => toggleProfileMenu(ev)}>
                          <span className={'golf-header__link--username'}>{props.user.first_name}</span>
                          <span
                            className={'golf-header__link--figure'}
                            style={{ backgroundImage: `url('${userPlaceholderBgImg}')` }}></span>
                        </NavLink>
                        <ProfileDropdown
                          className={`${profileMenuIsOpen ? 'is-active' : ''}`}
                          signOut={handleSignOut}
                        />
                      </li>
                    )}
                    {navMenuIsOpen && (
                      <>
                        <li className={'golf-header__link is-user-mobile'}>
                          <span
                            className={'golf-header__link--figure mr-16'}
                            style={{ backgroundImage: `url('${userPlaceholderBgImg}')` }}></span>
                          <span className={'golf-header__link--username'}>Hi, {props.user.first_name}</span>
                        </li>
                        <li className="mx-22 sm_tablet:mx-40">
                          <NavLink
                            to={'/profile'}
                            className={'d-flex items-center justify-between py-12 pr-12 pl-8'}
                            activeClassName="is-active">
                            <span className="font-extrabold leading-20 text-16">View Profile</span>
                            <Icon name="arrow" className={'ml-auto golf-icon right orange-icon'} />
                          </NavLink>
                          <hr className="border-t-1 border-ultra-light-grey my-4" />
                        </li>
                        <li className="mx-22 sm_tablet:mx-40">
                          <NavLink
                            to={'/profile/payment-details'}
                            className={'d-flex items-center justify-between py-12 pr-12 pl-8'}
                            activeClassName="is-active"
                            onClick={() => setNavMenuIsOpen(!navMenuIsOpen)}>
                            <span className="font-extrabold leading-20 text-16">Payment details</span>
                            <Icon name="arrow" className={'ml-auto golf-icon right orange-icon'} />
                          </NavLink>
                          <hr className="border-t-1 border-ultra-light-grey my-4" />
                        </li>
                        <li className="mx-22 sm_tablet:mx-40">
                          <NavLink
                            to={'/profile/transaction-history'}
                            className={'d-flex items-center justify-between py-12 pr-12 pl-8'}
                            activeClassName="is-active"
                            onClick={() => setNavMenuIsOpen(!navMenuIsOpen)}>
                            <span className="font-extrabold leading-20 text-16">Transaction history</span>
                            <Icon name="arrow" className={'ml-auto golf-icon right orange-icon'} />
                          </NavLink>
                          <hr className="border-t-1 border-ultra-light-grey my-4" />
                        </li>
                        <li className="mx-22 sm_tablet:mx-40">
                          <NavLink
                            to={'/profile/events/'}
                            className={'d-flex items-center justify-between py-12 pr-12 pl-8'}
                            activeClassName="is-active"
                            onClick={() => setNavMenuIsOpen(!navMenuIsOpen)}>
                            <span className="font-extrabold leading-20 text-16">My events</span>
                            <Icon name="arrow" className={'ml-auto golf-icon right orange-icon'} />
                          </NavLink>
                          <hr className="border-t-1 border-ultra-light-grey my-4" />
                        </li>
                        <li className="mx-22 sm_tablet:mx-40">
                          <NavLink
                            to={'/profile/settings'}
                            className={'d-flex items-center justify-between py-12 pr-12 pl-8'}
                            activeClassName="is-active"
                            onClick={() => setNavMenuIsOpen(!navMenuIsOpen)}>
                            <span className="font-extrabold leading-20 text-16">Account settings</span>
                            <Icon name="arrow" className={'ml-auto golf-icon right orange-icon'} />
                          </NavLink>
                          <hr className="border-t-1 border-ultra-light-grey my-4" />
                        </li>
                        <li className="mx-22 mt-auto mb-24 sm_tablet:mx-auto sm_tablet:is-1-of-3">
                          <Button
                            type={'primary-full-w'}
                            label={'Log out'}
                            onClick={() =>
                              UsersService.logout().then((res) => {
                                if (res.logout_url) window.location.href = res.logout_url
                                handleSignOut(true)
                              })
                            }
                          />
                        </li>
                      </>
                    )}
                  </>
                )}
              </ul>
            </>
          )}
          {(!userLoggedIn || props.hasCtaGG) && (
            <Button
              onClick={() => {
                if (props.goBackToRoute) localStorage.setItem('goBackToRoute', props.goBackToRoute)
                handleOauthSignIn()
              }}
              type={'sm_desktop:my-20 ml-auto self-start h-40 secondary-outline'}
              label={`Sign In ${width > 1024 ? 'with Golf Genius' : ''}`}
            />
          )}
        </div>
      </div>
      {showNotificationModal && (
        <NotificationModal
          notificationsAllRead={notificationsAllRead}
          close={() => {
            setShowNotificationModal(false)
            handleMarkAllAsRead()
          }}
        />
      )}
    </nav>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {
      dispatch(SignOut())
      dispatch(resetRegistratioInfoState())
    },
    updateProfile: (user: User) => dispatch(UpdateProfile(user)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
