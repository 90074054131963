import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import UsersService from "src/api/Users";
import { setGoBackToPage } from "src/store/reducers/location";

export const useAuthentication = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOauthSignIn = () => {
    const goBackToPage = history.location.pathname;
    dispatch(setGoBackToPage(goBackToPage));
    UsersService.getLoginUrl().then((res) => {
      let https_url;
      https_url = res.url.replace(/^http:\/\//i, "https://");
      window.location.href = https_url;
    });
  };

  return { handleOauthSignIn };
};
