import get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'
import { User } from 'src/Types/User'
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface HomeRouteProps extends RouteProps {
  user: User
}

const HomeRoute = ({ component: Component, ...rest }: HomeRouteProps) => {
  const userId = get(rest, 'user.id', null)
  const userLoggedIn = userId != null

  return (
    <Route
      {...rest}
      render={(props) => (userLoggedIn ? <Redirect to={'/profile/events'} /> : <Component {...props} />)}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  }
}

export default connect(mapStateToProps)(HomeRoute)
